<template>
  <div class="container-base section-container-padding no-padding-left">
    <recipe-menu :recipeLibrary="recipeLibrary"/>
  </div>
</template>

<script>
import RecipeMenu from "@/modules/recipeLibrary/components/RecipeMenu";
export default {
  components: {RecipeMenu},
  name: "RecipeLibrary",
  data() {
    return {
      intelligentIngestion: {
            title: 'intelligentIngestion',
            sub_title: 'Technical language and content is transformed through ingestion pipelines into an AI-native format, where it becomes available to the LI utilities. This gets content ready for applications and utility sets',
            icon: 'icon_recipelibrary_intelligentingestion.svg'
      },
      recipeLibrary: [{
            title: 'recipeLibrary.recipes.queryExpansion', 
            sub_title: 'asd',
            icon: 'icon_recipelibrary_intelligentingestion.svg'
          },{
            title: 'recipeLibrary.recipes.search', 
            sub_title: 'asd',
            path: '/search',
            icon: 'icon_recipelibrary_intelligentingestion.svg'
          },{
            title: 'recipeLibrary.recipes.summarization', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.ontologies', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.categorization', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.autoTagGeneration', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.tagManagement', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.autoQuestionGeneration', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.decisionTrees', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.documentPassagesSimilarity', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.pathwaysGraphs', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.contextualization', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.personalizationUserModeling', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          },{ 
            title: 'recipeLibrary.recipes.insights', 
            sub_title: '',
            path: '',
            icon: 'icon_recipelibrary_intelligentingestion.svg'

          }]
      }
    }
  }
</script>

<style scoped>
.no-padding-left{
  padding-left:0px;
}
</style>