<template>
  <b-card-text class="choose-recipe">
  </b-card-text>
</template>
<script>
export default {
  name: 'RecipeDefault',
  props: {
    subtitle: {}
  }
}
</script>
<style scoped>
.choose-recipe {
  padding-top: 20%;
  padding-bottom: 20%;
}
</style>