<template>
<div>
  <div class="item-recipe-header">
  <icon-page-header
  :icon="icon"
  :title="title"
  :subTitle="subTitle" />
  <b-col offset="1" cols="12" style="padding-left:0px;">
    <router-link :to="'/' + this.$store.getters.getInstance + '/ingestion-project/create'">
      <b-button class="button-primary openSans-600 fz-12 ta-center">Create & Define Recipe</b-button>
    </router-link>
  </b-col>
  </div>
  <b-row cols="12" offset="2" class="text-left">
    <b-col cols="6 item-recipe-category-title">
      Use Cases
    </b-col>
    <b-col cols="6 item-recipe-category-title item-recipe-category-title-filterby">
      <p>Filter By:</p>
    </b-col>
  </b-row>
  <b-row class="col-12 text-left">
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
  </b-row>
  <b-col cols="12 text-left item-recipe-category-title">
    Documentation
  </b-col>
  <b-row class="col-12 text-left">
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
  </b-row>
  <b-col cols="12 text-left item-recipe-category-title">
    For Use With:
  </b-col>
  <b-row class="col-12 text-left">
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
    <div class="col-3 item-recipe-box"></div>
  </b-row>
</div>
</template>
<script>
import IconPageHeader from '@/modules/common/components/IconPageHeader.vue'

export default {
  name: 'ItemRecipe',
  components: {IconPageHeader},
  props:{
    icon: String,
    title: String,
    subTitle: String
  }
}
</script>
<style scoped>
.choose-recipe {
  padding-top: 20%;
  padding-bottom: 20%;
}

.item-recipe-header{
  text-align:left; 
  margin: 10px 30px;
  padding: 20px;
  border-bottom:3px solid #e1e1e1;
}

.item-recipe-category-title{
  font-family: OpenSans;
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151515;
}

.item-recipe-category-title-filterby{
    color: #197c4d;
}

.item-recipe-box{
  min-height: 200px;
  border: solid 1px #979797;
  background-color: #d8d8d8;
}
</style>