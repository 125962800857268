<template>
  <div class="recipe-menu">
    <b-card no-body style="border: none;
    background-color: #f8f8f8;">
      <b-tabs pills card vertical class="recipe-menu-items openSans-14">
        <b-tab
            v-bind:title="$t('recipeLibrary.title') | titleUpperCase"
            active>
          <recipe-default />
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <router-link class="recipe-menu-items openSans-14" :to="'/' + $store.getters.getInstance + '/ingestion-project/create'">
              {{$t('recipeLibrary.recipes.intelligentIngestion')}}
            </router-link>
          </template>
        </b-tab>
        <b-tab v-for="recipe in recipeLibrary" :key="recipe.title" :title="$t(recipe.title)" disabled>
        <item-recipe :icon="recipe.icon" :title="$t(recipe.title)" :subTitle="recipe.sub_title"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>

import ItemRecipe from "@/modules/recipeLibrary/components/ItemRecipe";
import RecipeDefault from "@/modules/recipeLibrary/components/ItemRecipeDefault";
import tools from "@/modules/common/utils/tools";

export default {
  name: 'recipe-menu',
  components: {
    'recipe-default': RecipeDefault,
    'item-recipe': ItemRecipe,
    },
  props: {
    recipeLibrary: Array
  },
  filters: {
    titleUpperCase: tools.titleUpperCase
  }
}
</script>
<style>

</style>
